import { axiosApi, API_URL } from "../helpers/api_helper"
import authHeader from "../helpers/AuthHeader"

const all_users = (size, page, search) => {
    return axiosApi
        .get(API_URL + `get_all_users?limit=${size}&page=${page}&search=${search}`, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const multi_delete_users = (bodyParam) => {
    return axiosApi
        .delete(API_URL + "multiple_users", { headers: authHeader, data: bodyParam })
        .then((response) => {
            return response.data;
        });
};

const add_users = (bodyParam) => {
    return axiosApi
        .post(API_URL + "register", bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const view_users = (param) => {
    return axiosApi
        .get(API_URL + "get_users/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const edit_users = (param, bodyParam) => {
    return axiosApi
        .put(API_URL + "users/" + param, bodyParam, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const delete_users = (param) => {
    return axiosApi
        .delete(API_URL + "users/" + param, { headers: authHeader })
        .then((response) => {
            return response.data;
        });
};

const role = {
    all_users,
    multi_delete_users,
    add_users,
    view_users,
    edit_users,
    delete_users,
    API_URL,
};

export default role;